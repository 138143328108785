import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import React from "react";
import InvalidAccess from "./InvalidAccess";
import { toast } from "react-toastify";


export default function Expen(){
    const corp_id = localStorage.getItem("business_id");
    const user_id = localStorage.getItem("user");
    const ryts = localStorage.getItem("user_rights");
  
    const [loc, setLoc] = useState("");
    const [filtered, setFiltered] = useState("");
    const [cusList, setCustomerList] = useState([
    {
      id: 0,
      company: "",
      name: "",
      user_id: "",
    },
  ]);
  const getCustomer = {
    cusID: 0,
    corp_id: corp_id,
    module: "getCustomer",
    product: "quotationB",
  };

   async function AxiosCustomers(formData) {
    toast.warning("please wait!");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if (formData.module === "getCustomer") {
        if (res.data.data != undefined) {
          if (formData.cusID === 0) {
            setCustomerList(res.data.data);
          }
        }
        else{
          toast.warning("no data found");
        }
      }
    });
  }
useEffect(() => {
    AxiosCustomers(getCustomer);
    getMyLocation();
  }, []);
      function getMyLocation() {
            const location = window.navigator && window.navigator.geolocation;
            if (location) {
            location.getCurrentPosition((position) => {
                setLoc(position.coords.latitude+","+position.coords.longitude);
            }, (error) => {
                setLoc("error");
            })
            }
        }
  
  const handleFilter = (event)=>{
    const { name, value } = event.target;
    setFiltered(value);
  }
    return (
      <div>
        <Dashboard />
        <div id="content_area">
        <div id="list_section">
          <h2>Visits Records</h2>
          <h3><form style={{float:'none'}}>
            <input type="text" onChange={handleFilter} placeholder="filter list by name"  value={filtered}  />
          </form></h3>
          <br />
          <div>
            <ul>
                  <li >
                    
                    <button>View</button>
                  </li>
            </ul>
          </div>
        </div>
        <form method="post" name="customerForm">
          <h2>
            Location Tracking and Expense Management<br />  
            <small>** you must authorize location tracking in your device</small>
          </h2>
          {/* onChange={handleChange} */}
          {/* value={CurrentQuote.customer} */}
          <small>Depart Location:</small><br />
          <select required name="depart" >
            <option value=""> - - Depart Location - - </option>
            <option value={'Home'}>{'Home'}</option>
            <option value={localStorage.getItem("business")}>{localStorage.getItem("business")}</option>
            { cusList.map((cus) => {
              if(ryts==2 && cus.user_id !== user_id){ return false; }
                return  <option key={cus.id} value={cus.id}>{cus.company} ({cus.name} )</option> ;
            })}
          </select><br />
          <small>Arrival Location:</small>
          <select required name="arrive" >
            <option value=""> - - Arrival Location - - </option>
            <option value={'Home'}>{'Home'}</option>
            <option value={localStorage.getItem("business")}>{localStorage.getItem("business")}</option>
            { cusList.map((cus) => {
              if(ryts==2 && cus.user_id !== user_id){ return false; }
                return  <option key={cus.id} value={cus.id}>{cus.company} ({cus.name} )</option> ;
            })}
          </select>
          <small>Kilometers :</small>
            <input type="text" name="kms" value={''} placeholder="KM(s)" />
            <input type="hidden" name="loc" value={loc} /> 
          {(loc) ? <input type="submit" name="subMit" value="Save" /> : ""}
        </form><br /><br />
        <a target="_blank" href={'https://www.google.com/maps/place/'+loc}>Current Location</a>
      </div></div>
    )
  }